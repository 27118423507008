* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  #theme{
    display: none;
  }
  
  #theme:checked ~ .App{
    filter: invert(1)
  }
  
  .App{
    width: 100%;
    min-height: 100vh;
    background:white;
  }
  .main{
    max-width: 1000px;
    width: 100%;
    margin: auto;
  }
  
  img{
    object-fit: cover;
  }

  /* ----- AUTH ------- */
  @import url("./auth.css");
  
  /* ----- Loading ------- */
  @import url("./loading.css");
  
  /* ----- Header ------- */
  @import url("./header.css");


  /* ----- Avatar ------- */
  @import url("./avatar.css");


  /* ----- Profile ------- */
  @import url("./profile.css");

/* ----- Home ------- */
  @import url("./home.css");

  /* ----- Status Modal ------- */
   @import url("./status_modal.css");

  /* ----- Comment ------- */
  @import url("./comment.css");

  /* ----- Post Thumb ------- */
  @import url("./post_thumb.css");

  /* ----- Message ------- */
  @import url("./message.css");

  /* ----- Discover ------- */
  @import url("./discover.css");

  /* ----- Icons ------- */
  @import url("./icons.css");

  /* ----- CALL MODAL ------- */
  @import url("./call_modal.css");

   .mode{
     width: 100%;
     height: 100%;
     overflow: hidden;
   }