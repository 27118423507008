.comment_input{
    display: flex;
    align-items: center;
}

.comment_input input{
    background: #f7f7f7;
    border: none;
    outline: none;
    flex: 1;
    overflow: auto;
}

.comment_input .postBtn{
    border: 1px solid thin;
    padding: 0 10px;
    border-radius: 50%;
    outline: none;
    background: #f7f7f7;
    color: tomato;
    font-weight: 800;
}

/*------------ Comment----------- */
.comment_display{
    padding: 10px 25px;
}

.comment_display .comment_content{
    background: #eee;
    padding: 7px;
    border-radius: 10px;
    border-top-left-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.comment_display .comment_content .readMore{
    cursor: pointer;
    color: tomato;
}
.comment_display .comment_content .nav-item{
    cursor: pointer;
    opacity: 0;
}
.comment_display .comment_content:hover .nav-item{
    opacity: 1;
}
.comment_display .comment_content .dropdown-menu{
    position: absolute;
    right: 0;
    left: inherit;
}
.comment_display .comment_content .dropdown-item{
    display: flex;
    margin: 10px 0;
}
.comment_display .comment_content textarea{
    width: 100%;
    border: none;
    outline: none;
    padding: 0 10px;
}