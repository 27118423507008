.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading svg{
    font-size: 5px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    animation: text 1s ease-in-out infinite;
}

@keyframes text {
        50%{
            opacity: 0.1;
        }
}