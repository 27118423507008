.status{
    background: white;
    box-shadow: 0 0 5px #ddd;
    padding: 20px;
    border-radius: 5px;
}

.status .statusBtn{
    background: #f1f1f1;
    border: none;
    outline: none;
    border-radius: 30px;
    color: #555;
    padding: 0 10px;
    text-align: left;
}
.status .statusBtn:hover{
    background: #ddd;
}

/* --------Posts -------*/
.posts .card{
    box-shadow: 0 0 10px #ddd;
}

/* --------CardHeader -------*/
.posts .card_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px 25px;
    width: 100%;
}

.posts .card_header .card_name{
    transform: translateY(3px);
}
.posts .card_header .dropdown-menu{
    position: absolute;
    left: inherit !important;
    right: 0;
}
.posts .card_header .dropdown-item{
    display: flex;
    margin: 5px 0;
}

/*------- Post Card Body--------- */
.posts .card_body-content{
    padding: 0 25px;
    margin-top: -10px;
    margin-bottom: 10px;
}
.posts .card_body-content .readMore{
    color: tomato;
    cursor: pointer;
}

/*------- Post Card Footer--------- */
.posts .card_icon_menu{
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
}
.posts .card_icon_menu i{
    font-size: 25px;
    margin: 20px;
}
.posts .card_icon_menu img{
    margin: 10px;
    width: 25px;
    height: 25px;
    transform: translateY(-4px);
}